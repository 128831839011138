//@ts-ignore
import { Button } from '@kargo/ui';
import type { Kind, Size, Variant } from '@kargo/ui/button/types';
import { useEffect, useState } from 'react';
import styled from '@emotion/styled';

type Props = {
  title: React.ReactNode;
  children: React.ReactNode;
  kind?: Kind;
  variant?: Variant;
  size?: Size;
  className?: string;
  alignment?: 'left' | 'right';
};

const Container = styled.div<{ ref: any }>`
  position: relative;
  display: inline-block;
`;

const Content = styled.div<{ isOpen: boolean; alignment: 'left' | 'right' }>`
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  position: absolute;
  background: ${({ theme }) => theme.colors.backgroundStark};
  border-radius: 4px;
  margin-top: 10px;
  z-index: 5;
  ${({ alignment }) => (alignment === 'left' ? `left: 0` : `right: 0`)}
`;

const useClose = ({
  containerRef,
  isOpen,
  setIsOpen,
}: {
  containerRef: HTMLDivElement | undefined;
  isOpen: boolean;
  setIsOpen: (update: boolean) => void;
}) => {
  useEffect(() => {
    if (!containerRef) return;

    const onClick = (e: MouseEvent) => {
      if (e.target instanceof HTMLElement && !containerRef.contains(e.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      window.addEventListener('click', onClick);
      return () => window.removeEventListener('click', onClick);
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef, isOpen]);
};

const BubbleSelector = ({
  title,
  className = '',
  variant = 'default',
  kind,
  size = 'default',
  children,
  alignment = 'left',
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const [containerRef, setContainerRef] = useState<
    HTMLDivElement | undefined
  >();
  useClose({ containerRef, isOpen, setIsOpen });

  return (
    <Container ref={setContainerRef as any} className={className}>
      <Button
        size={size}
        kind={kind}
        variant={variant}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        {title}
      </Button>
      <Content isOpen={isOpen} alignment={alignment}>
        {children}
      </Content>
    </Container>
  );
};

export default BubbleSelector;
